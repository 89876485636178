import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Spin, notification,  message, Form, Input, Button} from 'antd';
import * as Actions from "./../../../store/user/actions";
import * as C from './../../../store/user/constants';
import propExists from "../../../core/library/objects/getprop";
import PermissionsSelect from "../../../components/list/permissions";
import {AppConsumer} from './../../../context/app';
import {t} from './../../../core/library/i18n';


class UsersEditForm extends React.Component {
    componentDidMount() {
        if (this.props.onMounted) {
            this.props.onMounted();
        }
    }

    render() {
        return null;
    }
}


const mapStateToProps = state => ({
    answer: state.UserReducer,
});

const mapDispatchProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch)
});

class UsersEditController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: propExists(this.props, 'match.params.id') ? this.props.match.params.id : false,
            userInfo: {},
            permissions: [],
            groups: [],
            isLoading: true,
            formLoading: false,
        };
    }

    updateUserInfo = (permissions = [], group) => {
        let userInfo = this.state.userInfo;
        userInfo.permissions = permissions;
        userInfo.group = group;
        this.setState({userInfo}, () => {
            this.props.actions.UpdateUser(this.state.id, {permissions, group });
        });
    };

    componentDidMount() {
        if (this.state.id === false) {
            message.error(t('USER_NOT_TRANSFER'))
        } else {
            this.props.actions.LoadPermissions();
        }
    }

    onSubmitForm = (user) => {
        if (this.state.id === 'create') {
            this.props.actions.CreateUser(user);
        } else {
            this.setState({formLoading: true}, () => {
                this.props.actions.UpdateUser(this.state.id, user);
            })
        }
        
    }


    componentWillReceiveProps(p) {
        if (p.answer && p.answer.state) {
            switch (p.answer.state) {
                case C.USERS_START_LOAD_PERMISSIONS:
                    this.setState({
                        isLoading: true
                    });
                    break;
                case C.USERS_FINISH_LOAD_PERMISSIONS:
                    let items = propExists(p.answer.data, 'data.list') && p.answer.data.data.list ? p.answer.data.data.list : [];
                    if (items.length > 0) {
                        items = items.map(i => {
                            let a = {...i};
                            a.key = `${i.id}`;
                            return a;
                        });
                    }
                    this.setState({
                        permissions: items,
                    }, () => {
                        this.props.actions.LoadUsersGroups();
                    });
                    break;
                case C.USERS_FINISH_UPDATE:
                    this.setState({isLoading: false, formLoading: false}, () => {
                        notification.success({
                            message: `User has been updated`,
                        });
                    });
                    break;
                case C.USERS_FINISH_LOAD_GROUPS:
                    this.setState({
                        groups: propExists(p.answer.data, 'data.list') ? p.answer.data.data.list : [],
                    }, () => {
                        if (this.state.id !== 'create') {
                            this.props.actions.LoadUserInfo(this.state.id);
                        } else {
                            this.setState({ isLoading: false });
                        }
                    });
                    break;
                case C.USERS_FINISH_LOAD_INFO:
                    let user = propExists(p.answer.data, 'data') ? p.answer.data.data : {};
                    // if (user && user.permissions && user.permissions.length > 0) {
                    //     user.permissions = user.permissions.map(i => {
                    //         if (!i) return null;
                    //         return i.id || null;
                    //     });
                    // }
                    this.setState({
                        userInfo: user,
                        isLoading: false,
                    });
                    break;
                case C.USERS_ERROR_LOAD_PERMISSIONS:
                    notification.error({
                        message: `Error load permission`,
                        description: `Please, try again later`
                    });
                    break;
                case C.USERS_ERROR_LOAD_INFO:
                    notification.error({
                        message: `Error load user info`,
                        description: `Please, try again later`
                    });
                    break;
                case C.USERS_ERROR_LOAD_GROUPS:
                    notification.error({
                        message: `Error load groups`,
                        description: `Please, try again later`
                    });
                    break;
                case C.USERS_ERROR_UPDATE:
                    this.setState({formLoading: false});
                    notification.error({
                        title: `Error update user permissions`,
                    });
                    break;
                case C.USERS_START_CREATE:
                    this.setState({formLoading: true});
                    break;
                case C.USERS_ERROR_CREATE:
                    this.setState({formLoading: false}, () => {
                        notification.error({
                            title: `Ошибка создания пользователя`,
                        });
                    });
                    break;
                case C.USERS_FINISH_CREATE:
                    console.log(`start create`, p);
                    message.success('Пользователь успешно создан');
                    window.location.href = `/users/${p.answer.data.data.id}`;
                    break;
                default:
                    break;
            }
        }
    }


    render() {
        return (
            <Spin spinning={this.state.isLoading}>

                <div className={`users--edit`}>
                    {this.state.isLoading === false && (
                        <React.Fragment>
                            <AppConsumer>
                                {context => {
                                    return (
                                        <UsersEditForm
                                            onMounted={() => {
                                                if (context.actions.updateHeader) {
                                                    context.actions.updateHeader({
                                                        title: this.state.id === 'create' ? t('USERS_CREATE') : `${t(`USERS_EDIT`)}: ${this.state.userInfo.email}`,
                                                    })
                                                }
                                            }}
                                        />
                                    );
                                }}
                            </AppConsumer>
                            <div className={'users--wrapper'}>
                                <WrappedUserFormEdit
                                    onSubmit={this.onSubmitForm}
                                    user={this.state.userInfo}
                                    loading={this.state.formLoading}
                                    isUpdate={this.state.id !== 'create'}
                                />
                                {this.state.id !== 'create' && (
                                    <>
                                        <h3 style={{marginTop: 30}}>{t('PERMISSIONS')}</h3>
                                        <PermissionsSelect
                                            groups={this.state.groups}
                                            permissions={this.state.permissions}
                                            selectedPermissions={this.state.userInfo.permissions}
                                            groupId={this.state.userInfo.group}
                                            user={this.state.userInfo}
                                            updatePermissions={this.updateUserInfo}
                                        />
                                    </>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </Spin>
        );
    }
}


class UserFormEdit extends React.Component {
    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            this.props.onSubmit(values);
          }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const user = this.props.user || {};
        return (
            <div className='users__form' >
                <Form onSubmit={this.onSubmit} autoComplete={false}>
                    <Form.Item label='Имя'>
                        {getFieldDecorator('firstname', {
                            rules: [{required: true, message: 'Укажите имя пользователя'}],
                            initialValue: user.firstname || '',
                        })(<Input type='text' placeholder='Введите имя пользователя' />)}
                    </Form.Item>
                    <Form.Item label='Фамилия'>
                        {getFieldDecorator('lastname', {
                            rules: [{required: true, message: 'Укажите фамилию пользователя'}],
                            initialValue: user.lastname || '',
                        })(<Input type='text' placeholder='Введите фамилию пользователя' />)}
                    </Form.Item>
                    <Form.Item label='Email'>
                        {getFieldDecorator('email', {
                            rules: [
                                {required: true, message: 'Укажите email пользователя'},
                                {type: 'email', message: 'Введите корректный email'}
                            ],
                            initialValue: user.email || '',
                        })(<Input type='email' placeholder='Введите email пользователя' />)}
                    </Form.Item>
                    <Form.Item label='Пароль'>
                        {getFieldDecorator('password', {
                            rules: this.props.isUpdate ? [] : [
                                {required: true, message: 'Укажите пароль пользователя'},
                            ],
                        })(<Input type='password' placeholder='Введите пароль пользователя' />)}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType='submit' loading={this.props.loading}>Отправить</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const WrappedUserFormEdit = Form.create({ name: 'users_create' })(UserFormEdit);

UsersEditController.propTypes = {
    actions: PropTypes.instanceOf(Object).isRequired,
    answer: PropTypes.instanceOf(Object).isRequired,
};

export const UsersEdit = connect(
    mapStateToProps,
    mapDispatchProps
)(UsersEditController);

export default UsersEdit;
